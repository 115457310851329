<template>
  <div>
    <b-row id="linhaIndicadores">
      <b-col sm="12" md="3">
        <b-card>
          <CardIndicador
            id="margemEfetivo"
            :isLoading="isLoadingIndicadores"
            title="Média de Margem Efetivos"
            :total="chartMargemEfetivos"
            :series="hardcodedSeries([10, 12, 14])"
          />
        </b-card>
      </b-col>

      <b-col sm="12" md="3">
        <b-card>
          <CardIndicador
            id="margemEfetivo"
            :isLoading="isLoadingIndicadores"
            title="Média de Margem Estáveis"
            :total="chartMargemEstavel"
            :series="hardcodedSeries([10, 12, 14])"
            barColor="#CDDC39"
          />
        </b-card>
      </b-col>

      <b-col sm="12" md="3">
        <b-card>
          <CardIndicador
            id="margemEfetivo"
            :isLoading="isLoadingIndicadores"
            title="Média de Margem Aposentados"
            :total="chartMargemInativo"
            :series="hardcodedSeries([10, 12, 14])"
            barColor="#689F38"
          />
        </b-card>
      </b-col>

      <b-col sm="12" md="3">
        <b-card>
          <CardIndicador
            id="margemEfetivo"
            :isLoading="isLoadingIndicadores"
            title="Média de Margem Pensionistas"
            :total="chartMargemPensionista"
            :series="hardcodedSeries([10, 12, 14])"
            barColor="#E91E63"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-card-code no-body v-if="acesso.CadastroTomadorConsulta">
      <b-card-body>
        <b-row>
          <b-col md="2">
            <b-form-group label="Pesquisar Por" label-for="opcao">
              <b-form-select id="opcao" :options="tipoOpcoes" v-model="pesquisar.tipoOpcoes" />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="this.pesquisar.tipoOpcoes != 2">
            <b-form-group label="Descrição" label-for="descricao">
              <b-form-input
                id="descricao"
                placeholder="Descrição"
                v-model="pesquisar.descricao"
                v-on:keydown.enter="carregarGrid"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="this.pesquisar.tipoOpcoes == 2">
            <b-form-group label="Descrição" label-for="descricao">
              <cleave
                id="descricao"
                placeholder="Descrição"
                v-model="pesquisar.descricao"
                class="form-control"
                :options="options.cleaveCPF"
                :formatter="maxlenght11"
                v-on:keypress="this.isNumber()"
              />
            </b-form-group>
          </b-col>
          <b-col md="auto">
            <b-button
              :disabled="isBusy"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 21px; width: 160px"
              @click="consultar"
            >
              {{ !isBusy ? 'Pesquisar' : '' }}
              <b-spinner small v-if="isBusy" />
            </b-button>
          </b-col>
          <b-col md="auto" aling="right" v-show="this.acesso.CadastroTomadorCriarNovo">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 21px; width: 160px"
              @click="novoRegistro()"
            >
              Novo Cadastro
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12" align="right" v-show="!this.acesso.CadastroTomadorConsulta && this.acesso.CadastroTomadorCriarNovo">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 21px; width: 160px"
              @click="novoRegistro()"
            >
              Novo Cadastro
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>

      <b-table
        v-show="items.length > 0"
        striped
        responsive
        class="position-relative"
        :per-page="pesquisar.perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="pesquisar.sortBy"
        :sort-desc.sync="pesquisar.sortDesc"
        :sort-direction="pesquisar.sortDirection"
        :busy="isBusy"
        @change="currentPage = $event"
        @sort-changed="sortingChanged"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong> Carregando...</strong>
          </div>
        </template>

        <template #cell(opcoes)="row">
          <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
            <template #button-content>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                title="Este botão contém as opções disponíveis para este registro"
              >
                <feather-icon icon="SettingsIcon" />
              </b-button>
            </template>
            <b-dropdown-item @click="visualizar(row.item.id)">
              <feather-icon icon="EyeIcon" size="16" />
              <span class="align-middle ml-50">Visualizar</span>
            </b-dropdown-item>
            <b-dropdown-item @click="editar(row.item.id)" v-if="acesso.CadastroTomadorEditar && !row.item.falecido">
              <feather-icon icon="EditIcon" size="16" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item @click="excluir(row.item.id)" v-if="acesso.CadastroTomadorExcluir">
              <feather-icon icon="XIcon" size="16" />
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell(avatar)="row">
          <b-avatar :src="row.item.foto != null ? row.item.foto.foto : null" />
        </template>
        <template #cell(categorias)="row">
          <span class="margimBadge" v-for="segurado in row.item.segurado" :key="segurado.id">
            <b-badge pill variant="primary" class="mr-1">
              {{ filterInativo(String(segurado.situacaoPrevidenciaria)).toUpperCase() }}
            </b-badge>
          </span>
        </template>
      </b-table>

      <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          style="align-items: center"
          label="Por Página"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>

        <!-- pagination -->
        <b-pagination
          v-model="pesquisar.currentPage"
          :total-rows="totalRows"
          :per-page="pesquisar.perPage"
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-body>

      <!-- Modal editar -->
      <b-modal
        id="modal-cadastro-tomador"
        ref="modal-cadastro-tomador"
        centered
        size="lg"
        title="Cadastro"
        no-close-on-backdrop
        no-close-on-esc
      >
        <modalCadastroTomador
          ref="cadastroTomador"
          :parametro="dados"
          @tomadorLoadingEvent="tomadorLoadingMethod"
          @atualizaGrid="carregarGrid"
          :isEditing="isEditing"
        />
        <template #modal-footer="{}">
          <b-button variant="outline-secondary" @click="$bvModal.hide('modal-cadastro-tomador')"> Fechar </b-button>
          <b-button variant="primary" @click.prevent="salvarTomador" :disabled="loading">
            {{ !loading ? 'Salvar' : '' }}
            <b-spinner small v-if="loading" />
          </b-button>
        </template>
      </b-modal>

      <!-- Modal visualizar -->
      <b-modal
        id="modal-visualizar-tomador"
        ref="modal-visualizar-tomador"
        centered
        size="lg"
        title="Visualizar"
        no-close-on-backdrop
        no-close-on-esc
      >
        <modalVisualizarTomador ref="visualizarTomador" :parametro="dados" />
        <template #modal-footer="{}">
          <b-button variant="outline-secondary" @click="$bvModal.hide('modal-visualizar-tomador')"> Fechar </b-button>
        </template>
      </b-modal>
    </b-card-code>
    <b-card class="text-center" v-else>
      <b-card-body>
        <b-icon icon="exclamation-triangle" variant="danger" font-scale="2"></b-icon>
        <b-card-title class="text-danger mt-2">Acesso Negado!</b-card-title>
        <b-card-text class="mb-1">Parece que seu usuário não tem permissão para realizar pesquisas.</b-card-text>
        <b-card-text class="mb-1"
          >Para obter acesso, por favor, solicite assistência ou permissões adicionais ao administrador do sistema.</b-card-text
        >
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import Cleave from 'vue-cleave-component'
  import modalCadastroTomador from '@/views/components/Tomador/modalCadastroTomador.vue'
  import modalVisualizarTomador from '@/views/components/Tomador/modalVisualizarTomador.vue'
  import { PascalCaseComEspaco, formatarData } from '@/libs/utils'
  import { isLetter, isNumber } from '@/libs/utils.js'
  import VueApexCharts from 'vue-apexcharts'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  var cardIndicadorMinHeight = 0

  const CardIndicador = {
    props: {
      isLoading: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
      },
      total: {
        type: String,
        default: 'R$ 0,00',
      },
      series: {
        type: Array,
        default: null,
      },
      barColor: {
        type: String,
        default: '#2196F3',
      },
    },
    components: {
      VueApexCharts,
    },
    data() {
      return {
        nomeRelatorio: { nome: 'Relatório de Proposta' },

        chartOptions: {
          chart: {
            type: 'bar',
            toolbar: {
              show: false,
            },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
              top: -15,
              bottom: -15,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '45%',
              startingShape: 'rounded',
              endingShape: 'rounded',
              borderRadius: 5,
              borderRadiusApplication: 'around',
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [this.barColor],
          xaxis: {
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            enabled: false,
            x: { show: false },
            y: { show: false },
          },
        },
      }
    },
    mounted() {
      this.checkTitleHeight()
    },
    methods: {
      boldRowClass(item, type) {
        return item.negrito ? 'font-weight-bold' : ''
      },
      checkTitleHeight() {
        if (this.$refs.title.offsetHeight > cardIndicadorMinHeight) {
          cardIndicadorMinHeight = this.$refs.title.offsetHeight
        }
      },
    },
    render() {
      return (
        <b-card no-body>
          <b-card-body class="pb-0">
            <strong class="d-inline-block" id="title" ref="title" style={`min-height:${cardIndicadorMinHeight}px`}>
              {this.title}
            </strong>
            {this.isLoading && <b-spinner big class="d-block mt-1 mb-2" />}
            {!this.isLoading && (
              <div style="position: relative;">
                <h1 class="font-weight-bolder mb-0 py-1" style="z-index:2;">
                  {this.total}
                </h1>
                <div style="position: absolute; right: 0; bottom:0; width: 70px; opacity: .9">
                  <vue-apex-charts height="50" options={this.chartOptions} series={this.series} />
                </div>
              </div>
            )}
          </b-card-body>
        </b-card>
      )
    },
  }

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      Cleave,
      modalCadastroTomador,
      modalVisualizarTomador,
      formatarData,
      BCardCode,
      CardIndicador,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        required,
        isLetter,
        isNumber,

        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
          sortBy: 'nomeCompleto',
          sortDesc: true,
          sortDirection: 'asc',
        },
        loading: false,

        tipoOpcoes: [
          { value: 1, text: 'NOME' },
          { value: 2, text: 'CPF' },
          { value: 3, text: 'MATRICULA' },
        ],
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
            numericOnly: true,
          },
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
          clevePhone: { phone: true, phoneRegionCode: 'BR', delimiter: ' ' },
          cleaveCEP: {
            delimiters: ['.', '.', '-'],
            blocks: [2, 3, 3],
          },
        },
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        fields: [
          { key: 'opcoes', label: 'OPÇÕES' },
          { key: 'avatar', label: '' },
          {
            key: 'nomeCompleto',
            label: 'NOME',
            sortable: true,
            formatter: (value) => {
              return value ? PascalCaseComEspaco(value) : ''
            },
          },
          { key: 'cpf', label: 'CPF', sortable: true },
          {
            key: 'dataNascimento',
            label: 'DATA DE NASCIMENTO',
            sortable: true,
            formatter: (value, key, item) => {
              return formatarData(value)
            },
          },
          {
            key: 'sexo',
            label: 'SEXO',
            sortable: true,
            formatter: (value) => {
              if (value == 'F') return 'Feminino'
              else return 'Masculino'
            },
          },
          { key: 'matriculas', label: 'MATRICULAS', sortable: false },
          {
            key: 'categorias',
            label: 'CATEGORIAS',
            sortable: false,
            formatter: (value) => {
              return value ? value.toUpperCase() : ''
            },
          },
        ],
        items: [],
        acesso: {
          CadastroTomadorConsulta: false,
          CadastroTomadorCriarNovo: false,
          CadastroTomadorExcluir: false,
          CadastroTomadorEditar: false,
        },
        dados: {},
        tomadorWithOrg: {},
        log: [],
        categoria: [
          {
            Ativo: 'primary',
            Inativo: 'light-success',
            Pensionista: 'light-danger',
          },
        ],
        chartMargemEfetivos: '0',
        chartMargemEstavel: '0',
        chartMargemInativo: '0',
        chartMargemPensionista: '0',
        isLoadingIndicadores: false,
        isEditing: true,
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    mounted() {
      this.carregaIndicadores()
      if (this.userData.role == 'master') {
        this.acesso.CadastroTomadorConsulta = true
        this.acesso.CadastroTomadorCriarNovo = true
        this.acesso.CadastroTomadorEditar = true
        this.acesso.CadastroTomadorExcluir = true
      } else {
        this.acesso.CadastroTomadorConsulta = this.userData.GrupoAcesso.CadastroTomadorConsulta
        this.acesso.CadastroTomadorCriarNovo = this.userData.GrupoAcesso.CadastroTomadorCriarNovo
        this.acesso.CadastroTomadorEditar = this.userData.GrupoAcesso.CadastroTomadorEditar
        this.acesso.CadastroTomadorExcluir = this.userData.GrupoAcesso.CadastroTomadorExcluir
      }
      // Conforme reunião retiramos a consulta automática
      // this.carregarGrid();
    },
    methods: {
      filterInativo(cat) {
        if (cat == 'Inativo') return 'Aposentado'
        return cat
      },
      consultar() {
        this.pesquisar.currentPage = 1
        this.carregarGrid()
      },
      carregarGrid() {
        this.isBusy = true
        useJwt
          .pesquisar('cadastro/tomador/Getpesquisar', this.pesquisar)
          .then((response) => {
            this.items = response.data.dados
            this.totalRows = response.data.total

            if (this.items.length == 0) {
              this.$message.error('Não foram encontrados tomadores!')
            }
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => (this.isBusy = false))
      },
      sortingChanged(sortOptions) {
        this.pesquisar.sortBy = sortOptions.sortBy
        this.pesquisar.sortDesc = sortOptions.sortDesc
        this.pesquisar.sortDirection = sortOptions.sortDesc ? 'desc' : 'asc'
        this.consultar()
      },
      novoRegistro() {
        this.isEditing = false
        this.dados = {
          nacionalidade: 76,
          sexo: '',
          genero: '',
          categoria: '',
          ufNaturalidade: '',
          foto: null,
          estadoCivil: '',
          desabilitarCampo: false,
        }

        this.$refs['modal-cadastro-tomador'].show()
      },
      excluir(item) {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente excluir o cadastro selecionado?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            useJwt
              .delete(`cadastro/tomador`, item)
              .then((response) => {
                this.carregarGrid()
              })
              .catch((error) => {
                console.error(error)
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Erro: Registro possui relacionamentos!',
                      icon: 'BellIcon',
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-right',
                    timeout: 8000,
                  },
                )
              })
          }
        })
      },
      visualizar(item) {
        this.dados.desabilitarCampo = true
        this.dados = { id: item, desabilitarCampo: true }

        this.$refs['modal-visualizar-tomador'].show()
      },
      editar(item) {
        this.isEditing = true
        this.dados.desabilitarCampo = true
        this.dados = { id: item, desabilitarCampo: true }

        this.$refs['modal-cadastro-tomador'].show()
      },
      salvarTomador() {
        this.$refs.cadastroTomador.salvar()
      },
      tomadorLoadingMethod(event) {
        this.loading = event
        this.$refs['modal-cadastro-tomador'].hide()
      },
      hardcodedSeries(data) {
        return [
          {
            name: '',
            data: data.map((d) => ({ x: '', y: d })),
          },
        ]
      },
      carregaIndicadores() {
        this.isLoadingIndicadores = true

        useJwt
          .post('/cadastro/tomador/GetIndicadoresTomador')
          .then((result) => {
            this.chartMargemEfetivos = String(result.data.chartEfetivo)
            this.chartMargemInativo = String(result.data.chartInativo)
            this.chartMargemEstavel = String(result.data.chartEstavel)
            this.chartMargemPensionista = String(result.data.chartPensionista)
            this.isLoadingIndicadores = false
          })
          .catch((error) => {
            console.error(error)
            this.isLoadingIndicadores = true
            this.$message.error('Erro ao consultar indicadores!')
          })
      },
      msgError(Title) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: Title,
              icon: 'BellIcon',
              variant: 'danger',
            },
          },
          {
            position: 'top-right',
            timeout: 5000,
          },
        )
      },
    },

    watch: {
      'pesquisar.currentPage': function () {
        this.carregarGrid()
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  div#modal-cadastro-tomador > div.modal-dialog.modal-lg.modal-dialog-centered,
  div#modal-visualizar-tomador > div.modal-dialog.modal-lg.modal-dialog-centered {
    max-width: 1200px;
  }

  .margimBadge > span {
    margin-top: 5px;
    margin-bottom: 5px;
  }
</style>
